// 订单列表
<template>
  <div class="pageContol listWrap templateList PointsMall">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">积分商城</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">积分订单</a>
        </span>
      </div>
      <div class="framePage-body">
        <div
          class="operationControl"
          style="display: flex; flex-direction: column; align-items: flex-start"
        >
          <div class="searchbox">
            <div
              title="订单号"
              class="searchboxItem ci-full"
              style="width: 330px"
            >
              <span class="itemLabel" style="width: 6rem">订单号:</span>
              <el-input
                v-model="orderSn"
                type="text"
                clearable
                size="small"
                placeholder="请输入订单号"
              />
            </div>
            <div
              title="商品类型"
              class="searchboxItem ci-full"
              style="width: 330px"
            >
              <span class="itemLabel" style="width: 8rem">商品类型:</span>
              <el-select
                v-model="productType"
                clearable
                placeholder="请选择商品类型"
                size="small"
                style="width: 100%"
              >
                <el-option label="题库" value="1"></el-option>
                <el-option label="精品课程" value="2"></el-option>
              </el-select>
            </div>
            <div
              title="商品名称"
              class="searchboxItem ci-full"
              style="width: 340px"
            >
              <span class="itemLabel" style="width: 8rem">商品名称:</span>
              <el-input
                v-model="productName"
                type="text"
                clearable
                size="small"
                placeholder="请输入商品名称"
              />
            </div>
          </div>
          <div class="searchbox" style="margin-top: 15px">
            <div
              title="买家姓名"
              class="searchboxItem ci-full"
              style="width: 330px"
            >
              <span class="itemLabel" style="width: 6rem">买家姓名:</span>
              <el-input
                v-model="userName"
                type="text"
                clearable
                size="small"
                placeholder="请输入买家姓名"
              />
            </div>
            <div
              title="买家手机号"
              class="searchboxItem ci-full"
              style="width: 330px"
            >
              <span class="itemLabel" style="width: 8rem">买家手机号:</span>
              <el-input
                v-model="mobile"
                type="text"
                clearable
                size="small"
                placeholder="请输入买家手机号"
              />
            </div>
            <div
              title="兑换时间段"
              class="searchboxItem ci-full time"
              style="width: 340px"
            >
              <span class="itemLabel" style="width: 8rem">兑换时间段:</span>
              <el-date-picker
                v-model="value1"
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                size="small"
              >
              </el-date-picker>
            </div>
            <div>
              <el-button type="primary" class="bgc-bv" round @click="getData()"
                >查询</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                width="100"
              />
              <el-table-column
                label="订单号"
                align="left"
                prop="orderSn"
                show-overflow-tooltip
                width="160px"
              />
              <el-table-column
                label="商品名称"
                align="left"
                prop="productName"
                show-overflow-tooltip
              />
              <el-table-column
                label="商品类型"
                align="left"
                prop="idcard"
                show-overflow-tooltip
                width="100"
              >
                <template slot-scope="scope">
                  {{ scope.row.productType == "1" ? "题库" : "精品课程" }}
                </template>
              </el-table-column>
              <el-table-column
                label="买家姓名"
                align="left"
                prop="userName"
                show-overflow-tooltip
              />
              <el-table-column
                label="买家手机号"
                align="left"
                prop="mobile"
                show-overflow-tooltip
              />
              <el-table-column
                label="兑换积分"
                align="left"
                prop="paymentMoney"
                show-overflow-tooltip
              />
              <el-table-column
                label="兑换时间"
                align="left"
                prop="createTime"
                show-overflow-tooltip
              />
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "PointsMall/orderList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      orderSn: "",
      productType: "",
      productName: "",
      userName: "",
      mobile: "",
      value1: "",
    };
  },
  computed: {},
  mounted() {},
  methods: {
    getData(pageNum = 1) {
      let params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        orderSn: this.orderSn || "",
        productType: this.productType || "",
        productName: this.productName || "",
        userName: this.userName || "",
        mobile: this.mobile || "",
      };
      if (this.value1 && this.value1.length) {
        params.createTime = this.value1[0];
        params.endTime = this.value1[1];
      }
      this.doFetch(
        {
          url: "/order/main/order-main/exchange/pageList",
          params,
          pageNum,
        },
        true,
        2
      );
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 2.5) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
  // beforeRouteLeave: resetKeepAlive,
  // watch: {
  //   $route: {
  //     handler: function (val, oldVal) {
  //       if (val.query.refresh) {
  //         this.getData(-1);
  //       }
  //     },
  //     // 深度观察监听
  //     deep: true,
  //   },
  // },
};
</script>

<style lang="less" >
.PointsMall {
  .time {
    .el-date-editor {
      .el-range-separator {
        padding: 0;
      }
    }
  }
}
</style>
